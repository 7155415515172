import React from 'react';

const LoanBreakdown = ({ tuitionRequest, downPayment, tuitionFinanced, originationFee, finalLoanAmount, monthlyPayment, interestRate, loanTerm, activeTuitionRequestIsInvalid }) => {
    const formatCurrency = (value) => value === null ? "" : `$${value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;

    const tuitionRequestDisplay = formatCurrency(tuitionRequest);
    const downPaymentDisplay = formatCurrency(downPayment);
    const tuitionFinancedDisplay = formatCurrency(tuitionFinanced);
    const originationFeeDisplay = formatCurrency(originationFee);
    const finalLoanAmountDisplay = formatCurrency(finalLoanAmount);
    const monthlyPaymentDisplay = formatCurrency(monthlyPayment);
    const interestRateDisplay = (interestRate * 100).toFixed(2) + "%";

    return (
        <>
            <div style={{ backgroundColor: 'rgba(18, 84, 59, 0.5)', border: '2px solid darkgreen', padding: '15px', marginTop: '20px' }}>
                <h1 className="text-xl text-center font-manrope"><b>Your Loan Breakdown</b></h1>
                <br/>

                {activeTuitionRequestIsInvalid ? (
                    <p>Please select a valid tuition request to see your loan breakdown.</p>
                ) : (
                    <>
                        <div className="math-line">
                            <span>Tuition still owed:</span>
                            <span>{tuitionRequestDisplay}</span>
                        </div>
                        {downPayment > 0 && (
                            <>
                                <div className="math-line">
                                    <span>Required down payment:</span>
                                    <span><b>-</b>{downPaymentDisplay}</span>
                                </div>
                                <hr className="border-black my-2" />
                                <div className="math-line">
                                    <span className="font-[600]">Tuition financed with Fortify:</span>
                                    <span>{tuitionFinancedDisplay}</span>
                                </div>
                                <br/>
                            </>
                        )}
                        <div className="math-line">
                            <span>Origination fee:</span>
                            <span>+{originationFeeDisplay}</span>
                        </div>
                        <hr className="border-black my-2" />
                        <div className="math-line">
                            <span className='font-[600]'>Your final loan amount:</span>
                            <span>{finalLoanAmountDisplay}</span>
                        </div>
                    </>
                )}
            </div>

            <div style={{ backgroundColor: 'lightblue', border: '2px solid darkblue', padding: '15px', marginTop: '20px' }}>
                <h1 className="text-xl text-center font-manrope"><b>Your Final Loan Details:</b></h1>
                <br/>
                {activeTuitionRequestIsInvalid ? (
                    <p>Please select a valid tuition request to see your loan details.</p>
                ) : (
                    <>
                        <div className="math-line">
                            <span className="font-[600]">Final loan amount:</span>
                            <span>{finalLoanAmountDisplay}</span>
                        </div>
                        <div className="math-line">
                            <span className="font-[600]">Annual interest rate:</span>
                            <span>{interestRateDisplay}</span>
                        </div>
                        <div className="math-line">
                            <span className='font-[600]'>Your monthly payment:</span>
                            <span>{monthlyPaymentDisplay}</span>
                        </div>
                        <div className="math-line">
                            <span className='font-[600]'>Loan term:</span>
                            <span>{loanTerm} payments</span>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default LoanBreakdown;