import React from 'react';
import FortifyIcon from '../FortifyIcon';

const Ineligible = ({ applicationData }) => {
  const reasons = applicationData?.student?.ineligibleReasons

  let title = "Sorry, you don't qualify for a Fortify loan.";
  let reason = "This may be due to your residency status, the state you live in, or your age. ";
  let proof = ""

  if (reasons.includes("age_under_18")) {
    title = "Sorry, we don't lend to applicants under 18.";
    reason = ""
    proof = " You may be required to provide proof of your age.";
  } else if (reasons.includes("residency_status")) {
    title = "Sorry, we don't lend to non-US residents.";
    reason = ""
    proof = " You may be required to provide proof of your US residency.";
  } else if (reasons.includes("state_of_residence")) {
    title = "Sorry, we don't lend to residents of your state.";
    reason = ""
    proof = " You may be required to provide proof of your state of residence.";
  }

  return (
    <div className='text-center'>
      <div className="flex justify-center">
        <FortifyIcon />
      </div>

      <h1 className="card-title"><b>{title}</b></h1>
      <p className='mb-4'>{reason}If you believe there was a mistake, please reach out to Fortify support.{proof}</p>
    </div>
  )
};

export default Ineligible;
