import axios from '../utils/axiosSetup';

export const fetchSchoolData = async (schoolId) => {
  const response = await axios.get(`public/schools/${schoolId}`);
  return response.data;
};

export const trackDisclosureEvent = async (eventType, schoolId, programId) => {
  const response = await axios.post('public/track_disclosure/cfba9c7e-7303-4e38-a287-b7195e951267', {
    event_type: eventType,
    school_id: schoolId,
    program_id: programId
  });
  return response.data;
};
