import React from 'react';
import { CircularProgress, Alert } from '@mui/material';
import SupportTag from './SupportTag';
import FortifyIcon from './FortifyIcon';

const LoadingComponent = ({error}) => {
  return (
    <div className="application-background">
      <div className="application-container">
        <FortifyIcon />

        <div className="w-4/5 pb-4 text-center">
            <h1 className="card-title"><b>Loading...</b></h1>
            {!error && <CircularProgress size={25} />}
            {error && <Alert severity="error" className="mt-4 text-center mx-auto" style={{ maxWidth: '400px' }}>{error}</Alert>}
            <div style={{ marginTop: '20px' }}>
            <SupportTag />
            </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;