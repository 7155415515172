import Intercom, { shutdown } from '@intercom/messenger-js-sdk';

export const initializeOrUpdateIntercomOnProd = () => {
  if (process.env.REACT_APP_APP_ENV !== 'prd') {
    return;
  }

  const user = JSON.parse(localStorage.getItem('user')) || {};

  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_ID,
    user_id: user.id || null,
    name: user.name || null,
    email: user.email || null,
    created_at: user.created_at ? Number(user.created_at) : null,
    user_hash: user.hash || null,
  });
};

export const shutdownIntercomOnProd = () => {
  if (process.env.REACT_APP_APP_ENV !== 'prd') {
    return;
  }

  shutdown();
}