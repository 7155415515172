import React from 'react';
import SupportTag from '../SupportTag';
import FortifyIcon from '../FortifyIcon';

const SchoolRejection = () => {
  return (
    <>
      <FortifyIcon />
      <div className='text-center'>
        <p className='card-title'><b>Your school declined your application.</b></p>
        <div className='space-y-4'>
          <p>It's possible that your school might have a monthly limit on how many students they can accept.</p>
          <p>Unfortunately, we can't proceed until your school certifies your identity and loan details. Please reach out to your school.</p>
          <SupportTag />
        </div>
      </div>
    </>
  )
};

export default SchoolRejection;


