import React, { useState } from 'react';
import { Button, Box, CircularProgress, Alert, Modal, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createStripeCheckoutSession } from '../../api/application';
import ProgressBar from '../shared/progressBar';
import SupportTag from '../SupportTag';
import FortifyIcon from '../FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const DownPayment = ({ applicationData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState('');
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

    const {
        school: {school_name},
        loan: {
            borrowingAmount,
            monthlyPayment,
            loanTerm,
            interestRate,
            apr
        }
    } = applicationData;

    const downPaymentAmount = parseFloat(applicationData?.loan?.requiredDownPayment) || 0;

    const formatCurrency = (value) => `$${value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;

    const formattedBorrowingAmount = formatCurrency(parseFloat(borrowingAmount));
    const formattedDownPaymentAmount = formatCurrency(parseFloat(downPaymentAmount));

    const firstPaymentDueDate = new Date(applicationData.loan.firstPaymentDueDate);
    const formattedFirstPaymentDate = firstPaymentDueDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    });
    const dayNumber = firstPaymentDueDate.getUTCDate();
    const ordinalSuffix = ['th', 'st', 'nd', 'rd'][(dayNumber % 10 > 3) ? 0 : (dayNumber % 100 - dayNumber % 10 !== 10) * dayNumber % 10];
  
    const formattedFirstPaymentDateWithOrdinal = formattedFirstPaymentDate.replace(/\d+/, `${dayNumber}${ordinalSuffix}`);


    const handleOpenTermsModal = () => {
        setIsTermsModalOpen(true);
    };

    const handleCloseTermsModal = () => {
        setIsTermsModalOpen(false);
    };

    const handleInitiatePayment = async () => {
        setIsLoading(true);
        setApiError('');
        try {
          const response = await createStripeCheckoutSession(applicationData.id);
          window.location.href = response.url;
        } catch (error) {
          logErrorToConsole("Failed to create Stripe checkout session", error);
          logErrorToSentryWithContext(error, {
            applicationId: applicationData.id,
          });

          setApiError('Failed to create Stripe checkout session. Please try again.');
          setIsLoading(false);
        }
    };

    const totalStepsToCompleteLoan = (applicationData?.loan?.requiredDownPayment && parseFloat(applicationData?.loan?.requiredDownPayment) > 0) ? 3 : 2;
  
    return (
        <>
            <FortifyIcon />

            <div className="step-indicator-container text-center pb-8">
                <p className="text-md text-gray-500 mb-2 font-manrope">Complete your loan</p>
                <p className="step-descriptor">Step 2 of {totalStepsToCompleteLoan}</p>
                <ProgressBar totalBars={totalStepsToCompleteLoan} filledBars={2} />
            </div>

            <div className='w-full sm:w-4/5 text-center'>
                <h1 className='pb-4 text-2xl font-manrope'><b>Your Down Payment</b></h1>
                <p className="pb-6">To complete your application, {school_name} requires a {formattedDownPaymentAmount} down payment. This is separate from and in addition to your Fortify loan, which covers your remaining tuition and an origination fee.</p>
                <p className='mb-8'>Your down payment will be sent directly to your school and fully applied towards your tuition.</p>
            </div>

            <div className='text-center md:w-4/5'>
                <Box
                    sx={{ bgcolor: 'grey.200', p: { xs:3, sm:4 }, borderRadius: 1 }}
                    className="flex flex-col text-center justify-center mb-8 space-y-2"
                >
                    <div style={{ width: '100%', textAlign: 'left' }}>
                        <h5><b>Important Notes:</b></h5>
                        <p>• This payment is processed securely through Stripe, a third-party payment processing service.</p>
                        <p>• The funds are paid directly to your school, not to Fortify.</p>
                        <p>• There are no additional fees charged to you for processing this down payment. The entire amount will go directly towards your tuition.</p>
                    </div>
                </Box>

                <div className='text-center max-w-lg mx-auto'>
                    <div className='w-full md:w-3/4 lg:w-2/3 text-center mb-6 mx-auto'>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <div className='w-full space-y-4'>
                                <Button className='w-full' variant="outlined" color="primary" onClick={handleOpenTermsModal}>Review My Loan Terms</Button>
                                <Button className='w-full' variant="contained" color="primary" onClick={handleInitiatePayment}>Make Down Payment</Button>
                            </div>
                        )}
                    </div>

                    {apiError && <Alert className='mx-auto text-center' style={{ maxWidth: '400px', marginTop: '16px', marginBottom: '16px' }} severity="error">{apiError}</Alert>}
                    <SupportTag className='mt-4'/>    
                </div>

                <Modal
                    open={isTermsModalOpen}
                    onClose={handleCloseTermsModal}
                    aria-labelledby="loan-terms-modal-title"
                    aria-describedby="loan-terms-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: 'calc(100% - 32px)', sm: 400 },
                        maxWidth: '100%',
                        maxHeight: '90vh',
                        overflow: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px'
                    }}>
                        <IconButton 
                            aria-label="close" 
                            onClick={handleCloseTermsModal}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography id="loan-terms-modal-title" variant="h6" component="h2" fontFamily="Manrope" gutterBottom>
                            Review Your Loan Terms*
                        </Typography>
                        <div id="loan-terms-modal-description">
                            <div className="down-payment-loan-terms">
                                <span className="font-[600]">Final loan amount:</span>
                                <span>{formattedBorrowingAmount}</span>
                            </div>
                            <div className="down-payment-loan-terms">
                                <span className="font-[600]">Your monthly payment:</span>
                                <span>{formatCurrency(parseFloat(monthlyPayment))}</span>
                            </div>
                            <div className="down-payment-loan-terms">
                                <span className="font-[600]">First payment due:</span>
                                <span>{formattedFirstPaymentDateWithOrdinal}</span>
                            </div>
                            <div className="down-payment-loan-terms">
                                <span className="font-[600]">Loan term:</span>
                                <span>{loanTerm} payments</span>
                            </div>
                            <div className="down-payment-loan-terms">
                                <span className="font-[600]">Annual interest rate:</span>
                                <span>{(interestRate * 100).toFixed(2)}%</span>
                            </div>
                            <div className="down-payment-loan-terms">
                                <span className="font-[600]">APR:</span>
                                <span>{(apr * 100).toFixed(2)}%</span>
                            </div>
                        </div>
                        <Typography variant="caption" sx={{ display: 'block', mt: 2, color: 'text.secondary' }}>
                            *Please review the disclosures on your loan packet before you sign to see your final loan terms. Your loan terms and first payment date may change based on certain factors, such as the date that you sign your loan packet.
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </>
    )
};

export default DownPayment;
