import React, { useState } from 'react';
import { TextField, Button, Alert, CircularProgress } from '@mui/material';
import { requestPasswordReset } from '../api/auth';
import FortifyIcon from './FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const RequestPasswordReset = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (emailAddress) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailAddress);
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      await requestPasswordReset(emailAddress);
      setSuccessMessage(true);
    } catch (error) {
      if (error.response?.data?.error === 'Account not found.') {
        setSuccessMessage(true);
      } else {
        logErrorToConsole("Error requesting password reset", error);
        logErrorToSentryWithContext(error, {
          emailAddress,
        });

        setErrorMessage(
          error.response?.data?.error || "There was an error sending you a password reset. We're looking into it!"
        );
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="application-background">
      <div className="application-container auth-container">
        <FortifyIcon />

        <div className='w-4/5 text-center'>
          <h1 className="text-2xl mb-4 font-manrope"><b>Reset your password</b></h1>
          {!successMessage && <p className="mb-8">Please enter the email you used to sign up, and we'll send you a password reset link.</p>}
        </div>

        {successMessage ? (
          <Alert severity="success">
            If an account with that email exists, we've sent a password reset link. Please check your email.
          </Alert>
        ) : (
          <>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              id="email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            {errorMessage && <Alert severity="error" className="mt-4 text-center" style={{ maxWidth: '400px' }}>{errorMessage}</Alert>}
            <div className="mt-8 w-full text-center">
              {
                isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={!isValidEmail(emailAddress)}
                    sx={{ height: '48px' }}
                  >
                    Request
                  </Button>
                )
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestPasswordReset;