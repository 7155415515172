import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyEmail } from '../api/auth';
import LoadingComponent from './LoadingComponent';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const queryParams = new URLSearchParams(location.search);
    const emailConfirmationCode = queryParams.get('emailConfirmationCode');

    if (!accessToken || !refreshToken) {
      navigate(`/login?redirectTo=${encodeURIComponent(location.pathname + location.search)}`);
    } else if (!emailConfirmationCode) {
      navigate('/application');
    } else {
      verifyEmail(emailConfirmationCode).then(() => {
        navigate('/application');
      }).catch((error) => {
        logErrorToConsole("Error verifying email", error);
        logErrorToSentryWithContext(error);

        setError(error.response?.data?.error || "There was an error verifying your email. We're looking into it!");
      });
    }
  }, [navigate, location.search, location.pathname]);

  return (
    <LoadingComponent error={error}/>
  );
};

export default VerifyEmail;
