import React, { useState } from 'react';
import { Button, Box, Alert, CircularProgress, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { declineAutopayPrompt, fetchOrCreateAutopayConsent } from '../../api/application';
import FortifyIcon from '../FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const AutopayPrompt = ({ applicationData, updateStage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDecline = async () => {
    handleCloseModal();
    setIsLoading(true);
    setError('');
    try {
      await declineAutopayPrompt(applicationData.id);
      updateStage('completed');
    } catch (error) {
      logErrorToConsole("Error declining autopay", error);
      logErrorToSentryWithContext(error, {
        applicationId: applicationData.id,
      });

      setError('An error occurred while processing your request - please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAgree = async () => {
    setIsLoading(true);
    setError('');
    try {
      const autopayResponse = await fetchOrCreateAutopayConsent(applicationData.id);

      if (autopayResponse.status === 'signed') {
        updateStage(autopayResponse.next_step ?? "completed");
      } else if (autopayResponse.signature_link) {
        window.open(autopayResponse.signature_link, '_self');
      }
    } catch (error) {
      logErrorToConsole("Error setting up autopay", error);
      logErrorToSentryWithContext(error, {
        applicationId: applicationData.id,
      });

      setError('An error occurred while creating your autopay document. Please try again.');
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <FortifyIcon />

      <Box className="w-full md:w-4/5 lg:w-3/4 text-center">
        <h1 className='pb-4 text-2xl font-manrope'><b>You're missing savings!</b></h1>

        <div className="flex items-center p-6 mt-4 mb-8 rounded-xl space-x-4" style={{ backgroundColor: '#FFF3D0' }}>
          <img src="/warning.svg" alt="Warning" className="w-12 h-12" />
          <p className="text-sm sm:text-base md:text-lg text-left">
            Get a <strong>0.25% interest rate discount</strong> & reduce your total payments by setting up autopay.
          </p>
        </div>

        <p className="text-left mb-6 underline">
          Here's why we strongly recommend you set up autopay:
        </p>

        <ul className="text-left list-disc pl-6 mb-6">
          <li>A <b>0.25% interest rate discount</b> on payments made via autopay.*</li>
          <li>Avoid missing payments, which could damage your credit score.</li>
          <li>Simplify your bills every month with automatic monthly payments.</li>
        </ul>

        <p className="text-left">
          By setting up autopay, you're taking a smart step towards financial responsibility and savings.
        </p>

        {error && (
          <Alert severity="error" className="mb-4">
            {error}
          </Alert>
        )}

        <Box className="flex justify-center space-x-4 mt-6">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div className="w-full flex flex-col space-y-3">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAgree}
                disabled={isLoading}
                className="w-full h-[50px] sm:h-[40px]"
              >
                Continue
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenModal}
                disabled={isLoading}
                className="w-full h-[50px] sm:h-[40px]"
              >
                Turn down the autopay discount
              </Button>
            </div>
          )}
        </Box>

        <div className='mt-4 text-left text-legal-gray' style={{ fontSize: '0.75rem' }}>
          <p>*Terms and conditions apply. Your 0.25% interest rate discount benefit will be applied as an equivalent discount for payments received via autopay and will be reflected in the payment application on your monthly billing statement and online payment history.</p>
        </div>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="autopay-decline-modal-title"
        aria-describedby="autopay-decline-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 'calc(100% - 32px)', sm: 400 },
          maxWidth: '100%',
          maxHeight: '90vh',
          overflow: 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px'
        }}>
          <IconButton 
            aria-label="close" 
            onClick={handleCloseModal}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <p id="autopay-decline-modal-title" className="text-2xl font-semibold mb-4">
            Are you sure?
          </p>
          <p id="autopay-decline-modal-description" sx={{ mt: 2 }}>
            You can <b>reduce your total payments</b> with the autopay discount. We strongly recommend setting it up to save money on your Fortify loan.
          </p>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Button variant="outlined" onClick={handleDecline} sx={{ flex: 1 }}>
              I'm sure
            </Button>
            <Button variant="contained" onClick={handleCloseModal} sx={{ flex: 1 }}>
              Go back
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AutopayPrompt;