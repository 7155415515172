import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';
import { usePlaidLink } from 'react-plaid-link';
import { postBankItem, saveBankMetadata } from '../api/student';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const ProcessPlaidOAuth = () => {
  const navigate = useNavigate();
  const [plaidError, setPlaidError] = useState('');

  const onPlaidSuccess = useCallback(async (publicToken, metadata) => {
    try {
      await postBankItem(
        localStorage.getItem('studentId'),
        publicToken,
        metadata
      );
      navigate('/application');
    } catch (error) {
      logErrorToConsole("Failed to post bank item", error);
      logErrorToSentryWithContext(error, {
        studentId: localStorage.getItem('studentId'),
      });

      setPlaidError('There was an error completing your bank verification. Please contact Fortify for assistance.');
    }
  }, []);

  const onPlaidExit = useCallback(async (error, metadata) => {
    try {
      await saveBankMetadata(
        localStorage.getItem('studentId'),
        error,
        metadata
      );
      if (error) {
        setPlaidError('There was an error completing your bank verification.');
      } else {
        navigate('/application');
      }
    } catch (error) {
      logErrorToConsole("Failed to save Plaid metadata on exit", error);
      logErrorToSentryWithContext(error, {
        studentId: localStorage.getItem('studentId'),
      });
    }
  }, []);

  const { open: openPlaidLink, ready: plaidLinkReady } = usePlaidLink({
    token: localStorage.getItem('plaidLinkToken'),
    receivedRedirectUri: window.location.href,
    onSuccess: onPlaidSuccess,
    onExit: onPlaidExit,
  });

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const plaidLinkToken = localStorage.getItem('plaidLinkToken');
    const studentId = localStorage.getItem('studentId');

    if (!accessToken || !refreshToken) {
      navigate('/login');
    } else if (!plaidLinkToken || !studentId) {
      navigate('/application');
    } else if (plaidLinkReady) {
      openPlaidLink();
    }
  }, [plaidLinkReady, openPlaidLink, navigate]);

  return (
    <LoadingComponent error={plaidError} />
  );
};

export default ProcessPlaidOAuth;