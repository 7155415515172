import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { refreshStripeCheckoutSessionStatus } from '../api/application';
import LoadingComponent from './LoadingComponent';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const ProcessCheckout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [checkoutError, setCheckoutError] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const queryParams = new URLSearchParams(location.search);
    const loanApplicationId = queryParams.get('application_id');

    if (!accessToken || !refreshToken) {
      navigate('/login');
    } else if (!loanApplicationId) {
      navigate('/application');
    } else {
      refreshStripeCheckoutSessionStatus(loanApplicationId)
        .then(response => {
            navigate('/application');
        })
        .catch((error) => {
          logErrorToConsole("Error fetching checkout session status", error);
          logErrorToSentryWithContext(error, {
            loanApplicationId,
          });

          setCheckoutError("There was an internal error. Your payment (if you made one) was not affected. Our team was notified of the error and we'll be in touch with more details.")
        });
    }
  }, [navigate, location.search]);

  return (
    <LoadingComponent error={checkoutError} />
  );
};

export default ProcessCheckout;