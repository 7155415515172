import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Alert } from '@mui/material';
import { fetchLoanApplicationDecision, fetchLoanApplicationById } from '../../api/application';
import LoanApplicationData from '../../models/LoanApplicationData';
import FortifyIcon from '../FortifyIcon';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const Decisioning = ({ applicationData, updateApplicationData }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { id: applicationId } = applicationData;

  useEffect(() => {
    fetchLoanApplicationDecision(applicationId)
      .then(() => {
        fetchLoanApplicationById(applicationId).then((response) => {
          const newApplicationData = new LoanApplicationData(response);
          updateApplicationData(newApplicationData);
        });
      })
      .catch((error) => {
        logErrorToConsole("Error submitting application", error);
        logErrorToSentryWithContext(error, {
          applicationId,
        });

        setLoading(false);
        setError("There was a technical error while submitting your application. Our team was notified and we'll look into it ASAP.")
      });
  }, [updateApplicationData, applicationId]);
  return (
    <>
      <FortifyIcon />

      <p className='card-title'><b>Submitted!</b></p>

      <div className='space-y-1 text-center mb-4'>
        <p>Processing your application now...</p>
        <p>(cross your fingers)</p>
      </div>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
          <CircularProgress size={25} />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : null}
    </>
  );
};

export default Decisioning;


