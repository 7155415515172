import React from 'react';

const ProgressBar = ({ totalBars, filledBars }) => {
  return (
    <div className="flex justify-center items-center space-x-2">
      {Array.from({ length: totalBars }, (_, index) => (
        <div
          key={index}
          className={`w-16 sm:w-20 md:w-24 h-2 ${index < filledBars ? 'bg-fortify-green' : 'bg-gray-400'}`}
        ></div>
      ))}
    </div>
  );
};

export default ProgressBar;
