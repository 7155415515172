import React from 'react';
import { CircularProgress } from '@mui/material';
import SupportTag from '../SupportTag';
import FortifyIcon from '../FortifyIcon';

const Loading = () => {
  return (
    <>
      <FortifyIcon />

      <div className="w-4/5 pb-4 text-center">
          <h1 className="card-title"><b>Loading...</b></h1>
          <CircularProgress size={25} />
          <div style={{ marginTop: '20px' }}>
          <SupportTag />
          </div>
      </div>
    </>
  )
};

export default Loading;
