import React from 'react';

const SupportTag = () => {
  return (
    <p className='text-sm text-legal-gray'>
        Need help? We're at <a href="mailto:help@fortifyedu.com" className="underline">help@fortifyedu.com</a>, or just call (214) 644-6444.
    </p>
  )
};

export default SupportTag;