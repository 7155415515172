import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Alert, CircularProgress } from '@mui/material';
import { fetchOrCreateLoanPacket } from '../../api/application';
import FortifyIcon from '../FortifyIcon';
import ProgressBar from '../shared/progressBar';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const Signature = ({ applicationData, updateStage }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleNextClick = async () => {
    setIsLoading(true);
    try {
      const loanPacketResponse = await fetchOrCreateLoanPacket(applicationData.id, { disclaimer_acknowledged: isChecked });
      if (loanPacketResponse.status === 'signed') {
        updateStage(loanPacketResponse.next_step ?? "completed"); // could redirect to autopay_prompt, or completed
      } else if (loanPacketResponse.signature_link) {
        window.open(loanPacketResponse.signature_link, '_self');
      }
    } catch (error) {
      logErrorToConsole("Error handling loan packet", error);
      logErrorToSentryWithContext(error, {
        applicationId: applicationData.id,
      });

      setApiError(error.response?.data?.error || 'An unexpected error occurred.');
      setIsLoading(false);
    }
  };

  const schoolName = applicationData?.school?.school_name || "your school";

  const totalStepsToCompleteLoan = (applicationData?.loan?.requiredDownPayment && parseFloat(applicationData?.loan?.requiredDownPayment) > 0) ? 3 : 2;

  return (
    <>
      <FortifyIcon />

      <div className="step-indicator-container text-center pb-8">
          <p className="text-md text-gray-500 mb-2 font-manrope">Complete your loan</p>
          <p className="step-descriptor">Step {totalStepsToCompleteLoan} of {totalStepsToCompleteLoan}</p>
          <ProgressBar totalBars={totalStepsToCompleteLoan} filledBars={totalStepsToCompleteLoan} />
      </div>

      <div className='w-full sm:w-4/5 text-center'>
          <h1 className='pb-4 text-2xl font-manrope'><b>Sign your loan contract</b></h1>
          <p className="pb-6">You're almost done - the last step is to review and sign your Fortify loan contract.</p>
      </div>

      <div className='text-center mx-auto my-4 w-full md:w-5/6 p-6 sm:p-8' style={{ backgroundColor: '#f5f5f5' }}>
        <p className="text-sm md:text-base text-left">
          <b style={{ fontSize: '20px', fontFamily: 'Manrope' }}>AN IMPORTANT PAYMENT NOTICE</b><br/><br/>
          <b style={{ fontSize: '18px', fontFamily: 'Manrope' }}>We know terms & conditions can be dull, but please read this carefully before you sign your loan.</b><br/><br/>
          You are taking out this loan to pay for your education at {schoolName}. Here's what this means for you:<br/><br/>
          <b>Loan Proceeds:</b> {schoolName} has chosen to wait to get paid. Instead of taking the full loan amount upfront, {schoolName} will receive payments only as you make them.<br/><br/>
          <b>Interest:</b> Interest will start accruing on your full loan balance from the first day of your repayment period.<br/><br/>
          <b>Your Responsibility:</b> Your timely payments are important. Each payment you make goes towards covering the cost of the education you're receiving.<br/><br/>
          <b>Why It Matters:</b> Staying up to date with your payments ensures that {schoolName} can continue to provide your educational program without interruption.<br/><br/>
          <b>Building Credit:</b> Making payments on time builds your credit, since Fortify reports to major credit bureaus. This will help you with future purchases such as a house or car. Missing payments will hurt your credit score.<br/><br/>
          <b>Making Payments:</b> You'll make your monthly payments through a company called <a href="https://www.zuntafi.com" target='_blank'><u><b>ZuntaFi</b></u></a>. You can reach them at <a href="https://www.zuntafi.com" target='_blank'><u>www.zuntafi.com</u></a> or by calling (800)-592-1270. We'll send you your ZuntaFi login information a few days after you sign your loan & start your program.<br/><br/>
          Please make sure you understand the above before you agree to the loan terms.<br/>
        </p>
      </div>

      <div className="text-left my-2 md:my-4">
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} name="readAndUnderstood" />}
          label="I have carefully read and I understand the above disclosure."
          disabled={isLoading}
          className="mb-4"
        />
      </div>

      <div className="text-center w-full">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button variant="contained" color="primary" disabled={!isChecked} style={{ minWidth: '150px', height: '40px', fontSize: '15px' }} onClick={handleNextClick}>Continue</Button>
        )}
      </div>
      {apiError && (
        <Alert style={{ maxWidth: '400px', margin: '20px auto 0 auto', textAlign: 'center' }} severity="error">{apiError}</Alert>
      )}
    </>
  )
};

export default Signature;
