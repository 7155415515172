import React from 'react';
import FortifyIcon from '../FortifyIcon';

const SchoolCertification = () => {
  return (
    <>
      <FortifyIcon />

      <div className='w-4/5 text-center'>
        <h1 className="card-title"><b>Waiting on your school</b></h1>
      </div>

      <div className="space-y-4 text-center" style={{maxWidth: '350px'}}>
        <p>Thanks! We're just waiting on your school to confirm your information. This usually takes under 48 hours.</p>
        <p>Once they've confirmed your tuition, you'll be able to see your exact monthly payment & loan terms.</p>
        <p>We'll email you when everything is ready. <b>You can close this window and come back later.</b></p>
      </div>
    </>
  )
};

export default SchoolCertification;
