import axios from '../utils/axiosSetup';
import { initializeOrUpdateIntercomOnProd } from '../utils/intercom';
import * as Sentry from "@sentry/react";
import { enableSentryReplayIntegrationIfNotEnabled } from '..';

export const registerAccount = async ({email, phoneNumber, firstName, lastName, password, schoolId, programId, startDate, source}) => {
    const response = await axios.post('auth/accounts', {
        email: email,
        phone: phoneNumber,
        first_name: firstName,
        last_name: lastName,
        password: password,
        school_id: schoolId,
        program_id: programId,
        start_date: startDate,
        source: source,
    });
    const user = {
        id: response.data.user_id,
        name: response.data.user_name,
        email: response.data.user_email,
        type: response.data.user_type,
        created_at: response.data.user_created_at,
        hash: response.data.user_hash,
    };

    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('accessToken', response.data.access_token);
    localStorage.setItem('refreshToken', response.data.refresh_token);
    Sentry.setUser({ email: email });

    initializeOrUpdateIntercomOnProd();
    enableSentryReplayIntegrationIfNotEnabled();

    return response.data;
};

export const login = async (email, password) => {
    const response = await axios.post('/auth/login', {
        email,
        password,
    });
    const user = {
        id: response.data.user_id,
        name: response.data.user_name,
        email: response.data.user_email,
        type: response.data.user_type,
        created_at: response.data.user_created_at,
        hash: response.data.user_hash,
    };

    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('accessToken', response.data.access_token);
    localStorage.setItem('refreshToken', response.data.refresh_token);
    Sentry.setUser({ email: email });

    initializeOrUpdateIntercomOnProd();
    enableSentryReplayIntegrationIfNotEnabled();

    return response.data;
};

export const triggerEmailConfirmation = async () => {
    const response = await axios.post(`auth/email-confirmation`);
    return response.data;
};

export const verifyEmail = async (emailConfirmationCode) => {
    const response = await axios.post(`auth/verify-email`, {
        email_confirmation_code: emailConfirmationCode,
    });
    return response.data;
};

export const requestPasswordReset = async (email) => {
    const response = await axios.post('auth/request-password-reset', { email });
    return response.data;
};

export const resetPassword = async (email, passwordResetCode, newPassword) => {
    const response = await axios.post('auth/reset-password', {
        email,
        password_reset_code: passwordResetCode,
        new_password: newPassword,
        tos_consent_received: false,
    });
    const user = {
        id: response.data.user_id,
        name: response.data.user_name,
        email: response.data.user_email,
        type: response.data.user_type,
        created_at: response.data.user_created_at,
        hash: response.data.user_hash,
    };

    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('accessToken', response.data.access_token);
    localStorage.setItem('refreshToken', response.data.refresh_token);

    initializeOrUpdateIntercomOnProd();
    enableSentryReplayIntegrationIfNotEnabled();

    return response.data;
};