import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchLoanApplicationById, fetchLoanApplicationsForAccount } from '../api/application';
import { shutdownIntercomOnProd } from '../utils/intercom';

import LoanApplicationData from '../models/LoanApplicationData';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';
import * as Sentry from "@sentry/react";

import PendingEmailVerification from './application/PendingEmailVerification';
import PersonalInformation from './application/PersonalInformation';
import InitiateIDVerification from './application/InitiateIDVerification';
import Decisioning from './application/Decisioning';
import Approval from './application/Approval';
import Rejection from './application/Rejection';
import Ineligible from './application/Ineligible';
import FailedIdentityVerification from './application/FailedIdentityVerification';
import SchoolRejection from './application/SchoolRejection';
import VerifyTuition from './application/VerifyTuition';
import PendingSchoolCertification from './application/PendingSchoolCertification';
import DownPayment from './application/DownPayment';
import Signature from './application/Signature';
import AutopayPrompt from './application/AutopayPrompt';
import Completed from './application/Completed';
import Loading from './application/Loading';
import PendingBankVerification from './application/PendingBankVerification';
import Cancelled from './application/Cancelled';
import Archived from './application/Archived';

const LoanApplication = () => {
  const [applicationData, setApplicationData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const updateApplicationStage = (newStage) => {
    setApplicationData({ ...applicationData, nextStep: newStage });
  };

  const fetchAndSetApplicationDataById = async (applicationId) => {
    try {
      const response = await fetchLoanApplicationById(applicationId);
      const applicationData = new LoanApplicationData(response);
      setApplicationData(applicationData);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        localStorage.clear();
        navigate('/signup');
      } else if (error.response && error.response.status === 403) {
        logout();
      } else {
        logErrorToConsole("Error fetching loan application by ID", error);

        if (error.response?.status === 401 && error.response?.data?.msg === "Token has expired") {
          console.log("Refresh token was expired. Logging out.");
        } else {
          logErrorToSentryWithContext(error, {
            applicationId,
          });
        }
      }
    }
  };

  const logout = () => {
    localStorage.clear();
    shutdownIntercomOnProd();
    navigate('/login');
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.email) {
      Sentry.setUser({ email: user.email });
    }

    if (!accessToken || !refreshToken) {
      navigate('/login');
    } else {
      const queryParams = new URLSearchParams(location.search);
      const applicationIdFromURL = queryParams.get('loan_application_id');

      const fetchApplications = async () => {
        if (applicationIdFromURL) {
          fetchAndSetApplicationDataById(applicationIdFromURL);
        } else {
          try {
            const data = await fetchLoanApplicationsForAccount();

            if (!data.student_id) {
              localStorage.clear();
              navigate('/signup');
            } else if (data.active_applications.length !== 0) {
              fetchAndSetApplicationDataById(data.active_applications[0]);
            } else if (data.archived_applications.length !== 0) {
              fetchAndSetApplicationDataById(data.archived_applications[0]);
            } else {
              localStorage.clear();
              navigate('/signup');
            }
          } catch (error) {
            logErrorToConsole("Failed to fetch applications", error);

            if (error.response?.status === 401 && error.response?.data?.msg === "Token has expired") {
              console.log("Refresh token was expired. Logging out.");
            } else {
              logErrorToSentryWithContext(error);
            }
          }
        }
      };

      fetchApplications();
    }
  }, [navigate, location.search]);

  useEffect(() => {
    if (applicationData?.nextStep) {
      window.scrollTo(0, 0);
    }
  }, [applicationData?.nextStep]);

  const renderScreen = () => {
    if (!applicationData) return <Loading logout={logout}/>;

    const applicationStage = applicationData.nextStep;

    switch (applicationStage) {
      case 'archived':
        return <Archived />;
      case 'email_verification':
        return <PendingEmailVerification applicationData={applicationData} />;
      case 'personal_information':
        return <PersonalInformation applicationData={applicationData} updateApplicationData={setApplicationData} />;
      case 'ineligible':
        return <Ineligible applicationData={applicationData}/>;
      case 'identity_verification':
        return <InitiateIDVerification applicationData={applicationData} updateApplicationData={setApplicationData} updateStage={updateApplicationStage} />;
      case 'decisioning':
        return <Decisioning applicationData={applicationData} updateApplicationData={setApplicationData} />;
      case 'rejected':
        return <Rejection />;
      case 'approval':
        return <Approval applicationData={applicationData} updateStage={updateApplicationStage} />;
      case 'pending_school_certification':
        return <PendingSchoolCertification updateStage={updateApplicationStage} />;
      case 'school_rejected':
        return <SchoolRejection />;
      case 'verify_tuition':
        return <VerifyTuition applicationData={applicationData} updateStage={updateApplicationStage} />;
      case 'down_payment':
        return <DownPayment applicationData={applicationData} updateStage={updateApplicationStage} />;
      case 'signature':
        return <Signature applicationData={applicationData} updateStage={updateApplicationStage} />;
      case 'cancelled':
        return <Cancelled />;
      case 'autopay_prompt':
        return <AutopayPrompt applicationData={applicationData} updateStage={updateApplicationStage} />;
      case 'completed':
        return <Completed applicationData={applicationData} />;
      case 'failed_identity_verification':
        return <FailedIdentityVerification applicationData={applicationData} />;
      case 'pending_bank_verification':
        return <PendingBankVerification />;
      default:
        console.error('Unknown application stage:', applicationStage);
        Sentry.captureException(new Error(`Unknown application stage for ${applicationData.id}: ${applicationStage}`));
        return <Loading />;
    }
  };

  const screensWithoutLogoutButton = ['completed', 'decisioning', 'ineligible']

  return (
    <div className="application-background">
      <div className="application-container">
        {renderScreen()}
      </div>
      { !screensWithoutLogoutButton.includes(applicationData?.nextStep) &&
        <div className="mt-4 logout-container">
          <button className="logout-button text-legal-gray" onClick={logout}>Logout</button>
        </div>
      }
    </div>
  );
};

export default LoanApplication;
